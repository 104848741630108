<script lang="ts" setup>

  // Idioma
	const { locale, t } = useI18n()

  // Config
  const runtimeConfig = useRuntimeConfig()
  
  // Head
  useSeoMeta({
    title: t('title'),
    description: t('description'),
    keywords: t('keywords'),
    ogTitle: t('title'),
    ogDescription: t('description'),
    robots: runtimeConfig.public.NODE_ENV === 'production' ? 'index, follow' : 'noindex'
  })

  useHead({
    link: [
      {
        rel: 'icon',
        href: '/favicon.ico'
      }
    ]
  })

  // Obtener mensajes de error
  const errors = useState('errorMesages')
  await callOnce(async () => {
    errors.value = await getStrapiData('error-message', {
      locale: locale.value
    })
  })

  // Es mobile
  const isMobile = useState('isMobile', () => true)
  onMounted(() => {
    isMobile.value = window.innerWidth <= 768
    window.addEventListener('resize', () => {
      isMobile.value = window.innerWidth <= 768
    })
  })

</script>

<template>
  <NuxtLoadingIndicator color="repeating-linear-gradient(to right,#ff620a 0%,#C40058 100%)" />
	<div class="dark:bg-gray-900 flex flex-col justify-center items-center">
		<Header />
		<slot />
		<Footer />
	</div>
</template>
